
const columns = [
    {
        label: 'Logo',
        name: 'logo',
    },

    {
        label: 'Name',
        name: 'name',
    },
    {
        label: 'Paybill',
        name: 'paybill',
        isNumber:true
        
    },
    {
        label: 'Action',
        name: 'action'
    }
]
export default columns