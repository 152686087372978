
const ip = '192.168.219.135'
export const url = 'http://' + ip + ':3000',
    appName = 'Sheltergram',
    api_public_key = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAv/lHNANwaDO7DVAk2kl6
coZKdv1Io15d1AxC0WTzwCtblNJ70jMsw8/bibo8xp4r6+esSWABZjxWIcjALdnS
p+bvazrCzO6aivQ8XxscQI2Zjle6UtnWcH1lQlIy+2a0mGDithOnHx17ivG686h2
M3JAovsgdLltfQS5OJV1XSwypZxHMVWvE/npMjd0A0NHx6vQFF3pHN8v3LrsM74J
kM67Lj2syXmoBOii9Mmg0BDoamREPVUO9m/w6LX9bA4zY+ZoO36vuAfqPEvokUvG
MDFyu51XiTS4p2Anyh6s1v0r9A3zpfWj+jh4amBYjF7Rkm7uErC+msFycEAqkal5
w+mpqZ2W+A1cDMFmtr7H4ORfrcWj7uypUt8TeDNMjurJgrp8lf6XtmbGcb24RrzX
ZJmpWcH9/nnl6iKkWDbLHDP1ouURGGGNA+sbftEmc5Ov1neukySkvPbISSu/dRSl
ms5C8aJltGH3man6K4g4pcA7Jdc+vPqHFs1mMpsrhUfEXdqNmDZmxcqO4GxLG+VW
SnCv/urKJ9/UuylcFFK5MxqurPUdqg/U1q1IOHl19QymcZ7V9nDuzpWCkLLMq4v4
gVOynf9Xnno9P8/r/nsmVz+SptTGU8F14a3v9nnV57mC5lfawcuhRINuSA7MnVBX
C/z2NvlmT1GsKHJrEBDOt7cCAwEAAQ==
-----END PUBLIC KEY-----`,
    prod = true,
    env: 'production' | 'development' = prod ? 'production' : 'development',
    TABLE_HEIGHT = '80vh'

