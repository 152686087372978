import { EventRegister } from 'react-native-event-listeners';
export interface EventProps {
    action?: string,
    info?: any,
    message?: string,
    type?: string,
    modal?: string | null
}
const EventsEmit = (
    title: 'app'|'modals',
    data: EventProps) => {
    EventRegister.emit(title, data)
},
    Events = EventRegister;
export {
    EventsEmit,
    Events
}