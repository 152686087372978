
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { FC, Fragment, useRef, useState } from "react";
import PageTitle from "../Common/PageTitle";
import { theme } from "../../theme";
import { LoadingButton } from "@mui/lab";
import { InputProps } from "../../types";
import { Apis, uploadToDigitalOceans } from "../../helpers";
import { BankProps } from "../../types/banks";
import { EventsEmit } from "../../configs/events";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
    bank?: BankProps[]
}
interface StateProps {
    logo: string | null;
    base64: {
        ext: string;
        data: string;
    } | null;
    adding: boolean;
    inputs: InputProps[];
}



const NewBank: FC<Props> = ({ bank }) => {
    const location=useLocation()
    const{record}=location?.state || {}
    const initialInputs: InputProps[] = [
        {
            label: 'Name',
            field: 'name',
            error: null,
            required: true,
            value: record?.name||''
        },
        {
            label: 'PayBill',
            field: 'paybill',
            error: null,
            required: false,
            type: 'text',
            value:record?.paybill|| ''
        },
    ];
    const [state, setState] = useState<StateProps>({
        inputs: initialInputs,
        logo: record?.logo || null,
        base64: null,
        adding: false
    });
    const { logo, base64, adding, inputs } = state;
  
    const fileInputRef = useRef(null) as any;
    const navigate = useNavigate()
    const changeInput = (input: string, obj: any) => {
        const updatedInputs = [...inputs];
        const inputIndex = inputs.findIndex(q => q.field === input);
        updatedInputs[inputIndex] = { ...updatedInputs[inputIndex], ...obj };
        setState({
            ...state,
            inputs: updatedInputs,
            adding: false
        });
    };
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const obj = Object.fromEntries((formData as any).entries());
        if (!obj.name || !obj.paybill) {
            let updateObj: any = {
                paybillError: "Please enter the Paybill.",
            };
            if (!obj.name)
                updateObj = { nameError: "Please enter name for the Bank." };

            setState({
                ...state,
                ...updateObj,
            });
        } else {
            setState({
                ...state,
                adding: true,
            });

            let base64Images: any = [];
            let newLogo: any;
            if (base64) base64Images.push(base64);
            newLogo = (await uploadToDigitalOceans({
                base64Images,
                // _id,
                folder: "logos",
            }))[0]?.image;
            Apis({
                module: "Banks",
                action: "addUpdateBank",
                logo: newLogo || logo,
                oldLogo: newLogo ? logo : undefined,
                name: obj.name,
                paybill: obj.paybill,
                _id: record?._id
            }).then((resp: any) => {
                const { savedBank, message } = resp
                if (savedBank) {


                    EventsEmit("app", {
                        info: {
                            message,
                            status: "success",
                        },
                    });
                    navigate("/banks");
                }
            })
                .catch((err) => {
                    console.log("Error creating Bank", err)
                    setState({
                        ...state,
                        adding: false,
                    });
                });
        }
    };

    const handleFocus = (errorKey: string) => {
        changeInput(errorKey, { error: null });
    };

    const handleLogoChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result as string;
                const data = base64Image.split(",")[1];
                const ext = base64Image.split("image/")[1].split(";")[0];
                setState({ ...state, base64: { ext, data } });
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <Fragment>
            <PageTitle title={(record?'Update':"Add") + " Bank"} backBtn />
            <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 10, ml: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Button
                                sx={{
                                    height: 100,
                                    width: 100,
                                    border: '1px dashed ' + theme.lightTheme.palette.primary.main,
                                    borderRadius: 3,
                                    textTransform: 'none'
                                }}
                                onClick={() => {
                                    if (fileInputRef.current) {
                                        fileInputRef.current.click();
                                    }
                                }}
                            >
                                {logo || base64 ? (
                                    <img
                                        alt={""}
                                        src={
                                            base64
                                                ? `data:image/${base64.ext};base64,${base64.data}`
                                                : (logo as string)
                                        }
                                        style={{
                                            height: 100,
                                            width: 100,
                                            borderRadius: 3,
                                            objectFit: 'cover'
                                        }}
                                    />
                                ) : (
                                    <Typography sx={{ textAlign: "center", mt: 0 }}>
                                        Upload
                                    </Typography>
                                )}
                                <input
                                    placeholder="just a placeholder"
                                    ref={fileInputRef}
                                    style={{
                                        height: 0,
                                        width: 0,
                                        opacity: 0,
                                    }}
                                    type={"file"}
                                    accept="image/*"
                                    onChange={handleLogoChange}
                                />
                            </Button>
                            <Typography sx={{ ml: 2, fontSize: 16 }}>
                                {logo ? 'Update' : 'Add'} Logo
                            </Typography>
                        </Box>
                    </Grid>
                    {inputs.map((field, index) => (
                        <Grid container spacing={2} sx={{ mt: 3, ml: 0 }} key={index}>
                            <Grid item xs={5}>
                                <TextField
                                    fullWidth
                                    required={field.required}
                                    label={field.label}
                                    name={field.field}
                                    value={field.value}
                                    onFocus={() => handleFocus(field.field)}
                                    onChange={(e) =>
                                        changeInput(field.field, { value: e.target.value })
                                    }
                                    error={Boolean(field.error)}
                                    helperText={field.error}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                <Grid item xs={12} sx={{ mt: 5 }}>
                    <Grid item xs={3}>
                        <LoadingButton
                            disabled={adding}
                            type="submit"
                            variant="contained"
                            sx={{ textTransform: 'none' }}
                            loading={adding}
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
};

export default NewBank

