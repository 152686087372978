import { Box, Button, CircularProgress, IconButton, Modal, Typography } from '@mui/material'
import { FC, useState } from 'react'
import PageTitle from '../Common/PageTitle'
import { useDropzone } from 'react-dropzone';
import { Close } from '@mui/icons-material';
import Show from '../Common/show';
import { LoadingButton } from '@mui/lab';
import { Apis, uploadToDigitalOceans } from '../../helpers';

interface Props {
    handleClose: (data: any) => void
    unitId: string
}

interface StatePRops {
    files: {
        ext: string
        data: string
    }[],
    uploading: boolean
}

const GalleryModal: FC<Props> = ({
    handleClose,
    unitId
}) => {
    const [state, setState] = useState<StatePRops>({
        files: [],
        uploading: false
    })
    const { files, uploading } = state

    const onDrop = (acceptedFiles: any) => {
        const readFiles = acceptedFiles.map((file: any) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64 = reader.result as string;
                    resolve({
                        ext: file.name.split('.').pop(),
                        data: base64.split(',')[1] // Removing the metadata prefix
                    });
                };
                reader.onerror = error => reject(error);
                reader.readAsDataURL(file);
            });
        });

        Promise.all(readFiles).then((newFiles: any) => {
            setState((prevState) => ({
                ...prevState,
                files: [...prevState.files, ...newFiles]
            }));
        });

    };
    const accept: { [key: string]: string[] } = {
        'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.webp']
    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true, accept });
    const removePhoto = (index: number) => {
        let Images = files
        Images.splice(index, 1)
        setState({
            ...state,
            files: Images
        })
    }
    const uploadMedia = async () => {
        setState({ ...state, uploading: true })
        const media = await uploadToDigitalOceans({
            base64Images: files,
            folder: 'unitImages',
            _id: unitId,
            field: 'url'
        })
        Apis({
            module: 'Properties',
            action: 'addUnitImages',
            media,
            unitId
        }).then((resp) => {
            handleClose(resp)
        }).catch((err) => {
            setState({ ...state, uploading: false })
        })
    }
    return (
        <Modal
            open
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{
                    width: '80%',
                    maxHeight: 650,
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    padding: 2
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <PageTitle title='Add Media' />
                        <IconButton
                            onClick={handleClose}
                        >
                            <Close />
                        </IconButton>
                    </Box>

                    <Box
                        sx={{
                            overflow: 'hidden',
                            overflowY: 'auto',
                            maxHeight: 400
                        }}
                    >
                        {
                            files.map((file, i) => (
                                <div
                                    key={file.data}
                                    style={{
                                        display: 'inline-block',
                                        flexDirection: 'column',
                                        width: 200,
                                        alignItems: 'flex-end',
                                        margin: 5
                                    }}
                                >
                                    <IconButton
                                        onClick={() => removePhoto(i)}
                                    >
                                        <Close />
                                    </IconButton>
                                    <img
                                        src={`data:image/${file.ext};base64,${file.data}`}
                                        style={{
                                            height: 200,
                                            width: 200,
                                            marginRight: 8,
                                            marginBottom: 0
                                        }} />
                                </div>
                            ))
                        }

                    </Box>
                    <Box {...getRootProps()} sx={{
                        border: '2px dashed grey',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        mt: 5
                    }}>
                        <input {...getInputProps()} accept='image/*' />
                        <Typography variant="body1">Drag 'n' drop some files here, or click to select files</Typography>
                    </Box>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={uploadMedia}
                        sx={{ mt: 2, textTransform: 'none' }}
                        loading={uploading}
                        disabled={!files.length}
                    >
                        <Show>
                            <Show.When>
                                <CircularProgress />
                            </Show.When>
                            <Show.Else>
                                Upload
                            </Show.Else>
                        </Show>
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    )
}

export default GalleryModal;
