import React, { Fragment, useState } from 'react';
import { Box, Tabs } from '@mui/material';
import PageTitle from '../Common/PageTitle';
import Media from './media';
import { LinkTab, samePageLinkNavigation } from '../Tenants/Tenant';
import { useLocation } from 'react-router-dom';
interface StateProps {
    currentTab: number
}

const UnitsGallery = () => {
    const [state, setState] = useState<StateProps>({
        currentTab: 0
    })
    const { currentTab } = state
    const { unit } = useLocation().state

    const tabs = [
        { label: 'Images', page: <Media type={'Images'} unitId={unit._id} /> },
        { label: 'Videos', page: <Media type={'Videos'} unitId={unit._id} /> },
    ]
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (
            event.type !== 'click' ||
            (event.type === 'click' &&
                samePageLinkNavigation(
                    event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ))
        ) {
            setState({
                ...state,
                currentTab: newValue
            });
        }
    };
    return (
        <Fragment>
            <PageTitle
                title={`Gallery`}
                backBtn
            />
            <Tabs
                value={currentTab}
                onChange={handleChange}
                aria-label="nav tabs example"
                role="navigation"
                sx={{ mt: 3, ml: 5 }}
            >
                {
                    tabs.map(({ label }) => (
                        <LinkTab key={label} label={label} />
                    ))
                }
            </Tabs>
            <Box sx={{
                mt: 5,
                ml: 8,
                height: '70vh'
            }}>
                {tabs[currentTab].page}
            </Box>
        </Fragment>
    );
};

export default UnitsGallery;
