import { Box, Button, CircularProgress, Dialog, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Apis } from '../../helpers';
import { EventsEmit } from '../../configs/events';
import { Connect, mapStateToProps } from '../../Redux';
import { PropertyProps } from '../../types/properties';

interface Props {
    close: () => void
    onDelete: (message?: string, error?: boolean) => void
    message:string
    unitId:string,
    selected:string[]
}
interface StateProps {
    processing: boolean
}
const DeleteMediaModal: FC<Props> = ({
    close,
    onDelete,
    message,
    unitId,
    selected
}) => {
    const [state, setState] = useState<StateProps>({
        processing: false
    })
    const { processing } = state
    const deleteItem = () => {
        setState({ ...state, processing: true })
        Apis({
            module:'Units',
            action:'deleteMedia',
            selected,
            unitId
        }).then(({ message, error }: any) => {
            onDelete(message, error)
            EventsEmit('app', {
                info: {
                    message,
                    status: 'error'
                },
            })
        }).catch(() => {
            setState({ ...state, processing: false })
        })
    }
    return (
        <Dialog
            open
            onClose={close}

            PaperProps={{
                sx: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
            slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'none',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)', // Set the background color you want
                    },
                    onClick: close, // Close the dialog on outside click
                },
            }}

        >
            <Box sx={{
                bgcolor: '#fff',
                width: 300,
                p: 2,
                borderRadius: 2,
                pt: 5,
                pr: 5
            }}>
                <Typography sx={{
                    fontWeight: 'bold',
                    mb: 2
                }}>Delete Media?</Typography>
                <Typography sx={{
                }}>{message}?</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pl: 1,
                    pr: 1,
                    mt: 5
                }}>
                    <Button
                        onClick={close}
                        sx={{
                            textTransform: 'none'
                        }}
                        variant={'contained'}
                    >Cancel</Button>
                    <Button
                        onClick={deleteItem}
                        color={'error'}
                        variant={'contained'}
                        sx={{
                            textTransform: 'none'
                        }}>{
                            processing ?
                                <CircularProgress size={15} style={{ color: '#fff' }} /> :
                                'Yes'
                        }</Button>
                </Box>
            </Box>
        </Dialog>
    )
}

export default Connect(mapStateToProps)(DeleteMediaModal);
