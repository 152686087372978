import NoData from '../Common/NoData'
import { Connect, mapDispatchToProps, mapStateToProps } from '../../Redux'
import PageTitle from '../Common/PageTitle'
import { Box, Button, List, TablePagination } from '@mui/material'
import { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { PropertyProps } from '../../types/properties'
import ProperyInfo from './properyInfo'
import ToolBar from '../Common/Toolbar'
import { Apis } from '../../helpers'
import _ from 'lodash';


interface StateProps {
    loading: boolean
    refreshing: boolean
    searchVal: string
    searching: boolean
    pageSize: number
    page: number
    count: number
    properties: PropertyProps[]
    distinctCities: string[]
    distinctPlaces: string[]
    filter: any
}

export
    const Properties: FC = ({

    }) => {
        const [state, setState] = useState<StateProps>({
            loading: true,
            refreshing: false,
            searchVal: '',
            searching: false,
            page: 0,
            pageSize: 5,
            count: 0,
            distinctCities: [],
            distinctPlaces: [],
            properties: [],
            filter: null
        })
        const { loading, searching, distinctCities, distinctPlaces, refreshing, searchVal, page, pageSize, count, properties, filter } = state
        const navigate = useNavigate()
        const filterOptions = [
            { label: 'City', field: 'city', options: distinctCities, type: 'multiSelect', selected: filter?.city||[] },
            { label: 'Place', field: 'placeName', options: distinctPlaces, type: 'multiSelect', selected: filter?.placeName||[] }
        ]
        const searchProperty = (searchVal: string) => getProperties({
            searchVal,
            processVariable: 'searching',
            newPage: 0
        })
        const getProperties = ({
            searchVal,
            newPage,
            newPageSize,
            processVariable,
            filter
        }: any) => {
            if (processVariable)
                setState({ ...state, [processVariable]: true })
            const NewPage = newPage > -1 ? newPage : state.page
            const NewPageSize = newPageSize || state.pageSize
            const afterLoad = {
                loading: false,
                searching: false,
                refreshing: false
            }
            Apis({
                module: 'Properties',
                action: 'getPropertiesInfo',
                searchVal,
                page: NewPage,
                pageSize: NewPageSize,
                filter: filter || state.filter
            }).then((resp: any) => {
                const { properties, count, distinctPlaces, distinctCities } = resp
                setState({
                    ...state,
                    count: count || state.count,
                    distinctPlaces: distinctPlaces || state.distinctPlaces,
                    distinctCities: distinctCities || state.distinctCities,
                    searchVal,
                    properties,
                    page: NewPage,
                    pageSize: NewPageSize,
                    ...afterLoad,
                    filter
                })
            }).catch(() => {
                setState({
                    ...state,
                    searchVal,
                    page: NewPage,
                    pageSize: NewPageSize,
                    ...afterLoad,
                    filter
                })
            })
        },
            startSearch = useCallback(_.debounce(searchProperty, 1000), [])
        const onFilter = (filter: any) => {
            getProperties({
                newPage: page,
                processVariable: 'loading',
                newPageSize: pageSize,
                filter
            })

        }
  
        useEffect(() => {
            getProperties({
                newPage: page,
                processVariable: 'loading',
                newPageSize: pageSize
            })
            return () => {

            }
        }, [])
        return (
            <Box sx={{ height: '100%', pr: 5 }}>
                <PageTitle title='Properties' />
                <ToolBar
                    addTitle={'Add Property'}
                    addPage={'/new_property'}
                    refreshing={refreshing}
                    onRefresh={() => getProperties({ processVariable: 'refreshing' })}
                    onSearch={startSearch}
                    filterOptions={filterOptions}
                    onFilter={onFilter}
                />
                {
                    !properties?.length || loading || searching ?
                        <NoData
                            message={`You don't have any property.`}
                            createMessage={searchVal ? undefined : `Add New Property`}
                            onCreate={() => navigate('/new_property')}
                            searchVal={searchVal}
                            loading={loading || searching}
                        /> :
                        <List sx={{ ml: 5, pr: 10 }}>
                            {
                                properties?.map(item => (
                                    <ProperyInfo item={item} key={item._id} />
                                ))
                            }
                            <TablePagination
                                component="div"
                                count={count}
                                page={page}
                                onPageChange={(_, newPage: number) => {
                                    getProperties({
                                        newPage,
                                        newPageSize: pageSize,
                                        processVariable: 'loading',
                                        searchVal
                                    })
                                }}
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    const newValue = parseInt(event.target.value, 10)
                                    getProperties({
                                        newPage: 0,
                                        newPageSize: newValue,
                                        processVariable: 'loading',
                                        searchVal
                                    })
                                }}
                                rowsPerPageOptions={[5, 10, 20, 25]}
                            />
                        </List>
                }
            </Box>
        )
    }

export default Connect(mapStateToProps, mapDispatchToProps)(Properties)
const person = {
    name: 'brian'
}
