import { Box } from "@mui/system"
import PageTitle from "../Common/PageTitle"
import ToolBar, { FilterParamProps } from "../Common/Toolbar"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material"
import columns from './columns'
import { CollectionsOutlined, DeleteOutlineOutlined, EditOutlined, LocalOfferOutlined } from "@mui/icons-material"
import { FC, Fragment, useCallback, useEffect, useState } from "react"
import MenuListPop from "../Common/menu"
import { PropertyProps, UnitsProps } from "../../types/properties"
import NoData from "../Common/NoData"
import { useNavigate } from "react-router-dom"
import { Apis, numberWithCommas } from "../../helpers"
import { Connect, mapStateToProps } from "../../Redux"
import DeleteModal from '../Common/deletePrompt'
import { TABLE_HEIGHT } from "../../configs"
import _ from 'lodash';

interface StateProps {
    units: UnitsProps[]
    showDeleteModal: null | UnitsProps
    loading: boolean
    refreshing: boolean
    searchVal: string
    searching: boolean
    pageSize: number
    page: number
    count: number
}

interface Props {
    selectedProperty?: PropertyProps;
    tablesColumnsFilters?: {
        units: string[];
    };
}
const Units: FC<Props> = ({
    selectedProperty,
    tablesColumnsFilters
}) => {
    const [state, setState] = useState<StateProps>({
        units: [],
        showDeleteModal: null,
        loading: true,
        refreshing: false,
        searchVal: '',
        searching: false,
        page: 0,
        pageSize: 20,
        count: 0
    })
    const { units, loading, showDeleteModal, searchVal, page, pageSize, count, refreshing } = state
    const menuList = [
        { label: 'Edit', icon: <EditOutlined /> },
        { label: 'Add Label', icon: <LocalOfferOutlined /> },
        { label: 'Gallery', icon: <CollectionsOutlined /> },
        { label: 'Delete', icon: <DeleteOutlineOutlined /> }
    ]
    const DisplayColums = columns.filter(col => tablesColumnsFilters?.units.includes(col.name))
    const filterOptions: FilterParamProps[] = []
    const navigate = useNavigate()
    const unitNames = units.map(q => q.name)
    const searchUnit = (searchVal: string) => getPropertyUnits({
        searchVal,
        processVariable: 'searching',
        newPage: 0
    })
    const startSearch = useCallback(_.debounce(searchUnit, 1000), [])
    const getPropertyUnits = ({
        searchVal,
        newPage,
        newPageSize,
        processVariable,
        filter
    }: any) => {
        if (processVariable)
            setState({ ...state, [processVariable]: true })
        const NewPage = newPage > -1 ? newPage : state.page
        const NewPageSize = newPageSize || state.pageSize
        const afterLoad = {
            loading: false,
            searching: false,
            refreshing: false,
            page: NewPage,
            pageSize: NewPageSize,
            searchVal
        }
        Apis({
            module: 'Properties',
            action: 'getPropertyUnits',
            _id: selectedProperty?._id,
            searchVal,
            page: NewPage,
            pageSize: NewPageSize
        }).then((resp: any) => {
            const { count, units } = resp

            setState({
                ...state,
                units,
                count: count || state.count,
                ...afterLoad
            })
        }).catch(() => {
            setState({
                ...state,
                ...afterLoad
            })
        })
    }
    useEffect(() => {
        getPropertyUnits({
            processVariable: 'loading'
        })
        // eslint-disable-next-line 
    }, [])
    return (
        <Box sx={{ pr: 5, height: '100%' }} >
            <PageTitle title={'Units'} />
            <ToolBar
                columns={columns}
                unitNames={unitNames}
                addTitle={'Add Unit'}
                addPage={'/new_unit'}
                columnsFilter={'units'}
                refreshing={refreshing}
                onRefresh={() => getPropertyUnits({ processVariable: 'refreshing' })}
                onSearch={startSearch}
                filterOptions={filterOptions}
            />
            {
                !units?.length || loading ?
                    <NoData
                        message={`You don't have any unit.`}
                        createMessage={searchVal ? undefined : `Add New Unit`}
                        onCreate={() => navigate('/new_unit')}
                        loading={loading}
                        searchVal={searchVal}
                    /> :
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer sx={{ maxHeight: TABLE_HEIGHT }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            DisplayColums.map(({ label }) => (
                                                <TableCell sx={{ fontWeight: 'bold' }} key={label} /*align={column.align}*/>
                                                    {label}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        units.map((unit, i) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={unit.name}>
                                                    {
                                                        DisplayColums.map(({ label, name, isNumber }, x) => {
                                                            const occupiedUnits = (unit.numberOfUnits) - (unit.vacantUnits || 0),
                                                                expectedRevenue = 500
                                                            let value = (unit as any)[name] || 'None'
                                                            value = isNumber ? numberWithCommas(value) : value
                                                            return (
                                                                <TableCell key={label + '' + unit.name} /*align={column.align}*/>
                                                                    {
                                                                        name === 'occupiedUnits' ?
                                                                            occupiedUnits :
                                                                            name === 'occupiedUnits' ?
                                                                                expectedRevenue :
                                                                                name === 'action' ?
                                                                                    <MenuListPop onSelect={(option) => {
                                                                                        switch (option) {
                                                                                            case 'Edit':
                                                                                                navigate('/new_unit', { state: { unit } })
                                                                                                break;
                                                                                            case 'Add Label':
                                                                                                navigate('/new_unitLabel', { state: { unit } })
                                                                                                break;
                                                                                            case 'Gallery':
                                                                                                navigate('/UnitsGallery', { state: { unit } })
                                                                                                break;
                                                                                            default:
                                                                                                setState({
                                                                                                    ...state,
                                                                                                    showDeleteModal: unit
                                                                                                })
                                                                                                break;
                                                                                        }
                                                                                    }} menuList={menuList} /> :
                                                                                    value
                                                                    }
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={(_, newPage: number) => {
                                getPropertyUnits({
                                    newPage,
                                    newPageSize: pageSize,
                                    processVariable: 'loading',
                                    searchVal
                                })
                            }}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                const newValue = parseInt(event.target.value, 10)
                                getPropertyUnits({
                                    newPage: 0,
                                    newPageSize: newValue,
                                    processVariable: 'loading',
                                    searchVal
                                })
                            }}
                            rowsPerPageOptions={[5, 10, 20, 25, 30, 50]}
                        />
                    </Paper>
            }
            {
                showDeleteModal ?
                    <DeleteModal
                        item={showDeleteModal}
                        close={() => setState({ ...state, showDeleteModal: null })}
                        onDelete={() => {
                            const itemIndex = units?.findIndex(q => q._id === showDeleteModal._id) as number
                            const Items = [...units as UnitsProps[]]
                            Items?.splice(itemIndex, 1)
                            setState({
                                ...state,
                                showDeleteModal: null,
                                units: Items
                            })

                        }}
                        deleteField={'unitId'}
                        page='Unit'
                        module={'Properties'}
                        itemName={showDeleteModal?.name}
                        unitId={showDeleteModal._id}
                    /> :
                    <Fragment />

            }
        </Box>
    )
}

export default Connect(mapStateToProps)(Units);
