import { createTheme, } from '@mui/material';

// Create a custom theme with the desired primary color
export default createTheme({
    palette: {
        primary: {
            main: '#8c7c0c',  
            contrastText:'#F5F1DA'
        },
    },
});
