import { Box, List, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import PageTitle from '../Common/PageTitle'
import Toolbar from '../Common/Toolbar'
import NoData from '../Common/NoData'
import { useNavigate } from "react-router-dom"
import { BankProps } from '../../types/banks'
import { useEffect, useState } from 'react'
import { Apis } from '../../helpers'
import DisplayColums from './columns'
import MenuListPop from '../Common/menu'
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material'
import Show from '../Common/show'
import DeletePrompt from '../Common/deletePrompt'


interface StateProps {
    data: BankProps[],
    refreshing: boolean,
    loading: boolean,
    showDeleteModal: null | BankProps
}
const Banks = () => {
    const [state, setState] = useState<StateProps>({
        data: [],
        refreshing: false,
        loading: true,
        showDeleteModal: null
    })
    const menuList = [
        { label: 'Edit', icon: <EditOutlined /> },
        { label: 'Delete', icon: <DeleteOutlineOutlined /> }
    ]
    const navigate = useNavigate()
    const { data, refreshing, loading, showDeleteModal } = state
    const handleSubmit = () => {

    }
    const getBanks = ({ refreshing }: any) => {
        if (refreshing)
            setState({ ...state, refreshing: true })
        Apis({
            module: 'Banks',
            action: 'getBanks'
        }).then((data: any) => {
            setState({ ...state, data, loading: false })
        }).catch(() => {
            setState({
                ...state,
                loading: false
            })
        })
    }
    useEffect(() => {
        getBanks({})
    }, [])

    return (
        <Box sx={{ height: '100%', pr: 5 }}>
            <PageTitle title='Bank' />
            {!loading && data.length ?
                <Toolbar
                    addTitle={'Add Bank'}
                    addPage={'/new_bank'}
                    refreshing={refreshing}
                    onRefresh={() => getBanks({ refreshing: true })}

                /> : null
            }
            {
                !data?.length || loading ?
                    <NoData
                        onCreate={() => navigate('/new_bank')}
                        loading={loading}


                    /> :
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer sx={{ maxHeight: '70vh' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            DisplayColums.map(({ label }) => (
                                                <TableCell sx={{ fontWeight: 'bold' }} key={label} /*align={column.align}*/>
                                                    {label}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.map((bank, index) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={bank._id}>
                                                    {DisplayColums.map(({ name }) => {
                                                        let value = (bank as any)[name]

                                                        return (
                                                            <TableCell >
                                                                <Show>
                                                                    <Show.When isTrue={name === "logo"}>
                                                                        <img style={{ height: 50, width: 50, objectFit: "contain" }} src={value} />
                                                                    </Show.When>
                                                                    <Show.When isTrue={name === 'action'}>
                                                                        <MenuListPop menuList={menuList} onSelect={(option) => {
                                                                            if (option === 'Edit')
                                                                                navigate('/new_bank', {
                                                                                    state: {
                                                                                        record: bank
                                                                                    }
                                                                                })
                                                                            else if (option === 'Delete')
                                                                                setState({
                                                                                    ...state,
                                                                                    showDeleteModal: bank as any
                                                                                })
                                                                        }} />
                                                                    </Show.When>
                                                                    <Show.When isTrue={Boolean(showDeleteModal)}>
                                                                        <DeletePrompt
                                                                            onDelete={() => {
                                                                                const Data = data
                                                                                const itemIndex = Data.findIndex(bank => bank._id === showDeleteModal?._id)
                                                                                Data.splice(itemIndex, 1)
                                                                                setState({
                                                                                    ...state,
                                                                                    showDeleteModal: null,
                                                                                    data: Data
                                                                                })
                                                                            }}
                                                                            close={() => setState({ ...state, showDeleteModal: null })}
                                                                            page='Bank'
                                                                            item={showDeleteModal}
                                                                            itemName=''
                                                                            module='Banks'
                                                                            bankId={showDeleteModal?._id}
                                                                        />
                                                                    </Show.When>

                                                                    <Show.Else>
                                                                        {value}
                                                                    </Show.Else>
                                                                </Show>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Paper>
            }
            {
                <Show>

                </Show>
            }

        </Box>
    )
}

export default Banks
