import axios from "axios";
import { store } from "../Redux";
import { Crypt } from "hybrid-crypto-ts";
import { api_public_key, env } from "../configs";
import AWS, { S3 } from "aws-sdk";
// import { ImagesUpload } from "../types";
import moment from "moment";
import { Buffer } from "buffer";
import { parsePhoneNumber } from 'libphonenumber-js'
import { EventsEmit } from "../configs/events";


const crypt = new Crypt();
export const Apis = (data: any, token?: string) =>
  new Promise((resolve, reject): void => {
    const jwtToken = store.getState().jwtToken,
      encrypted = crypt.encrypt(
        api_public_key,
        JSON.stringify({ ...data, as: "client", platform: "web", isWeb:true })
      );
    const url = String(process.env['REACT_APP_API_URL_' + env.toUpperCase()])
    axios[data.get ? 'get' : 'post'](url, encrypted, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (jwtToken || token)
      }
      // onUploadProgress(progressEvent) {
      //     console.log(progressEvent)
      // },

    }).then((res: any) => {
      resolve(res.data);
    }).catch((e: any) => {
      if (e.response?.data?.action === 'logOut')
          EventsEmit('modals', e.response.data)
      else if (e.response.status !== 504)
          EventsEmit('modals', { action: 'toastMsg', type: 'error', message: 'Network error occured. Please try again later.' })
      reject(e)
    });
  }),
  numberWithCommas = (number: number | string) => {
    if (!number || number == 'None')
      number = 0

    let numberString = (number as number).toFixed(2).toString(); // toFixed(2) adds two decimal places

    const parts = numberString.split(".");
    let integerPart = parts[0];
    const decimalPart = parts.length > 1 ? "." + parts[1] : "";

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    numberString = integerPart + decimalPart;

    return numberString;
  },
  uploadToDigitalOceans = async (data: any) => {
    const { base64Images, folder, _id, field } = data;
    try {
      // Fetch temporary security credentials from API
      const awsData: any = await Apis({
        module: "Users",
        action: "fetchSpaceCredentials",
      });
      const date = moment(new Date()).format("YYYY-MM-DD-HH-mm-s");

      const { accessKeyId, secretAccessKey, endpoint } = awsData;

      const s3 = new S3({
        accessKeyId,
        secretAccessKey,
        endpoint,
        s3ForcePathStyle: true,
      });

      const uploadPromises = base64Images.map(
        async ({ data, ext }: any, index: number) => {
          // console.log({ base64, isCoverPhoto });

          const fileName = `${folder + "/" + _id ? _id + "/" : ""
            }${date}/${index}/${"image"}.${ext}`;


          const buffer = Buffer.from(data, "base64");
          const params: S3.PutObjectRequest = {
            Bucket: env,
            Key: fileName,
            Body: buffer,
            ACL: "public-read",
            ContentType: `image/${ext}`
          };
          try {

            const uploadedImage = await s3.upload(params).promise();

            return {
              [field || 'image']: uploadedImage.Location,
            };
          }
          catch (err) {
          }
        }
      );

      const uploadedImages = await Promise.all(uploadPromises);

      return uploadedImages;
    } catch (error) {
      console.error("Error uploading images to DigitalOcean Spaces:", error);
      return "error";
    }
  },
  getFormattedPhone = (phone: string) => {
    try {
      return parsePhoneNumber(phone).format('INTERNATIONAL')
    } catch (err) {
      return false
    }
  },
  getYouTubeId = (url: string) => {
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  },
  capitalizeFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);