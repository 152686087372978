import { Add, Api, CheckCircle, Circle, CircleOutlined, Segment } from '@mui/icons-material'
import { Box, Button, IconButton, List, ListItem, ListItemButton, Popover, TextField, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import GalleryModal from './addMedia'
import Show from '../Common/show'
import NoData from '../Common/NoData'
import { MediaProps } from '../../types/properties'
import { Apis, getYouTubeId } from '../../helpers'
import AlertDialogSlide from '../Common/alert'
import DeleteMedia from './deleteMedia'
import { EventsEmit } from '../../configs/events'
import { LoadingButton } from '@mui/lab'
interface Props {
    type: 'Images' | 'Videos'
    unitId: string
}

interface StatePRops {
    showModal: boolean
    loading: boolean
    media: MediaProps[]
    isSelect: boolean,
    selected: string[]
    showDeleteModal: boolean
    anchorEl: HTMLButtonElement | null
    clickedItem: string | null
    youtube: {
        error: string | null
        url: string
    },
    processingYoutubeUrl: boolean
}

const Media: FC<Props> = ({
    type,
    unitId
}) => {
    const [state, setState] = useState<StatePRops>({
        showModal: false,
        loading: true,
        media: [],
        isSelect: false,
        selected: [],
        showDeleteModal: false,
        anchorEl: null,
        clickedItem: null,
        youtube: {
            url: '',
            error: null
        },
        processingYoutubeUrl: false
    })
    const { showModal, loading, media, isSelect, selected, showDeleteModal, anchorEl, clickedItem, youtube, processingYoutubeUrl } = state
    const getMedia = () => {
        const afterLoad = {
            loading: false,
            showModal: false
        }
        Apis({
            module: 'Properties',
            action: 'getMedia',
            unitId,
            type
        }).then((media: any) => {
            setState({
                ...state,
                media,
                ...afterLoad
            })

        }).catch((err) => {
            setState({
                ...state,
                ...afterLoad
            })
        })
    }
    const Video = media.find(q => q.mediaType == 'video')
    const VideoUrl = Video?.url
    const imgMedia = media.filter(q => q.mediaType === 'image')
    const buttons = type === 'Videos' && VideoUrl ? ['Delete Video'] : type === 'Images' && imgMedia.length ? [!selected.length ? 'Delete all' : null, selected.length ? 'Delete selected' : null, isSelect ? 'Cancel' : 'Select'].filter(q => Boolean(q)) : []

    const validateUrl = () => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = youtube.url.match(regex);
        if (match) {
            setState({
                ...state,
                processingYoutubeUrl: true
            })
            Apis({
                module: 'Units',
                action: 'AddYoutubeUrl',
                unitId,
                youtubeUrl: youtube.url
            }).then(({ message, newMedia }: any) => {
                setState({
                    ...state,
                    processingYoutubeUrl: false,
                    media: [...media, newMedia],
                    youtube: {
                        url: '',
                        error: null
                    }
                })
                EventsEmit('app', {
                    info: {
                        message,
                        status: 'success'
                    },
                })
            }).catch((err) => {
                setState({
                    ...state,
                    processingYoutubeUrl: false
                })

            })
        }
        else
            setState({
                ...state,
                youtube: {
                    ...youtube,
                    error: 'Invalid YouTube URL format'
                }
            })
    };
    const onHeaderBtnClick = (btn: any) => {
        if (['Cancel', 'Select'].includes(btn))
            setState({
                ...state,
                isSelect: !isSelect,
                selected: []
            })
        else if (btn === 'Delete Video')
            setState({ ...state, showDeleteModal: true, selected: [String(Video?._id)] })
        else
            setState({ ...state, showDeleteModal: true })
    }
    const onMediumClick = (id: string) => {
        if (isSelect) {
            const itemIndex = selected.indexOf(id)
            let Selected = [...selected]
            if (itemIndex > -1)
                Selected.splice(itemIndex, 1)
            else
                Selected = [...selected, id]
            setState({ ...state, selected: Selected })
        }
    }
    const SelectedMedia = selected.length ? selected : media.map(q => q._id)
    const popoverItemClick = (clicked: string, mediumId: string) => {
        if (clicked === 'Delete')
            setState({
                ...state,
                showDeleteModal: true,
                selected: [mediumId]
            })
        else
            Apis({
                module: 'Units',
                action: 'makeCoverPhoto',
                unitId,
                mediumId
            }).then(({ message }: any) => {
                EventsEmit('app', {
                    info: {
                        message,
                        status: 'success'
                    },
                })
                setState({ ...state, anchorEl: null })
            }).catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        getMedia()

        return () => {

        }
    }, [])

    return (
        <Box>
            <div style={{ marginBottom: 10 }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxWidth: '95%'
                }}>
                    <Show>
                        <Show.When isTrue={type === 'Images'}>
                            <Button
                                sx={{ textTransform: 'none' }}
                                onClick={() => {
                                    setState({
                                        ...state,
                                        showModal: true
                                    })
                                }}
                            >
                                <Add />
                                Add {type}
                            </Button>
                        </Show.When>
                    </Show>
                    <Box>
                        {
                            buttons.map((q) => (
                                <Button
                                    sx={{
                                        textTransform: 'none',
                                        textDecoration: 'underline'
                                    }}
                                    key={q}
                                    onClick={() => onHeaderBtnClick(q)}
                                >{q}</Button>
                            ))
                        }
                    </Box>
                </Box>
            </div>
            <Show>
                <Show.When isTrue={loading}>
                    <Box sx={{
                        height: '70vh'
                    }}>
                        <NoData loading />
                    </Box>
                </Show.When>
                <Show.Else>
                    <Show>
                        <Show.When isTrue={type === 'Images'}>

                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={() => setState({ ...state, anchorEl: null })}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <List>
                                    {
                                        ['Make Cover Photo', 'Delete'].map((item, i) => (
                                            <ListItemButton
                                                sx={{
                                                    borderBottom: i == 0 ? '1px solid #DBDBDB' : ''
                                                }}
                                                key={item}
                                                onClick={() => popoverItemClick(item, String(clickedItem))}
                                            >
                                                {item}
                                            </ListItemButton>
                                        ))
                                    }

                                </List>
                            </Popover>
                            {
                                imgMedia.map((medium) => (
                                    <Box
                                        sx={{
                                            display: 'inline-block',
                                            position: 'relative'
                                        }}
                                        key={medium._id}
                                    >

                                        <img
                                            src={medium.url}
                                            style={{
                                                height: 200,
                                                width: 200,
                                                marginRight: 8,
                                                marginBottom: 0
                                            }}
                                            onClick={() => onMediumClick(medium._id)}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '95%',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <IconButton
                                                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                                    setState({
                                                        ...state,
                                                        anchorEl: event.currentTarget,
                                                        clickedItem: medium._id
                                                    })
                                                }
                                            >
                                                <Segment sx={{ color: '#fff' }} />
                                            </IconButton>
                                            <Show>
                                                <Show.When isTrue={isSelect}>
                                                    <IconButton
                                                        onClick={() => onMediumClick(medium._id)}
                                                    >
                                                        <Show>
                                                            <Show.When isTrue={selected.includes(medium._id)} >
                                                                <CheckCircle sx={{ color: '#B0E5F2' }} />
                                                            </Show.When>
                                                            <Show.Else>
                                                                <CircleOutlined sx={{ color: '#B0E5F2' }} />
                                                            </Show.Else>
                                                        </Show>
                                                    </IconButton>
                                                </Show.When>
                                            </Show>
                                        </Box>
                                    </Box>
                                ))
                            }
                        </Show.When>
                        <Show.Else>
                            <Show>
                                <Show.When isTrue={Boolean(VideoUrl)}>
                                    <div style={{
                                        position: 'relative',
                                        paddingBottom: '56.25%',
                                        height: 0,
                                        overflow: 'hidden',
                                        maxWidth: '90%',
                                        background: '#000'
                                    }}>
                                        <iframe
                                            title="YouTube Video"
                                            src={`https://www.youtube.com/embed/${getYouTubeId(String(VideoUrl))}`}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                        />
                                    </div>
                                </Show.When>
                                <Show.Else>
                                    <Box sx={{
                                        position: 'relative',
                                        height: 0,
                                        pt: 30,
                                        maxWidth: '90%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <TextField
                                            sx={{
                                                mb: 10,
                                                width: '70%'
                                            }}
                                            variant={'standard'}
                                            label={'Youtube Url'}
                                            placeholder={'Enter Youtube Url here'}
                                            helperText={youtube.error}
                                            error={Boolean(youtube.error)}
                                            value={youtube.url}
                                            onChange={c => setState({ ...state, youtube: { error: null, url: c.target.value } })}
                                            onFocus={() => setState({
                                                ...state,
                                                youtube: {
                                                    ...youtube,
                                                    error: null
                                                }
                                            })}
                                        />
                                        <LoadingButton
                                            sx={{ textTransform: 'none' }}
                                            variant={'contained'}
                                            loading={processingYoutubeUrl}
                                            onClick={validateUrl}
                                        >Add Video</LoadingButton>
                                    </Box>
                                </Show.Else>
                            </Show>

                        </Show.Else>
                    </Show>
                </Show.Else>
            </Show>
            <Show>
                <Show.When isTrue={showModal}>
                    <GalleryModal
                        handleClose={(media: { image: string }[]) => {
                            setState({
                                ...state,
                                showModal: false
                            })
                            getMedia()
                        }}
                        unitId={unitId}
                    />
                </Show.When>
            </Show>
            <Show>
                <Show.When isTrue={showDeleteModal}>
                    <DeleteMedia
                        close={() => setState({ ...state, showDeleteModal: false })}
                        onDelete={() => {
                            let newMedia = media.filter(q => !SelectedMedia.includes(q._id))
                            setState({
                                ...state,
                                selected: [],
                                media: newMedia,
                                showDeleteModal: false,
                                isSelect: false
                            })
                        }}
                        selected={SelectedMedia}
                        unitId={unitId}
                        message={`Are you sure you want to delete ${selected.length ? 'the selected items' : ' all items'}?`}
                    />
                </Show.When>
            </Show>

        </Box>
    )
}

export default Media
